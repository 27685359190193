<template>
      <!-- 背景图 -->
	  <div style="width: 100%; position: fixed; z-index: 1000;">
			<div style="width: 100%;; height:50px; background:#ffffff;"></div>
			<div style="height: 150px; width: 62%;;margin:0 19%; background:#ffffff; margin-top: -50px;" id="tmenu" v-on:scroll="onscroll()">
				<div style="width: 100%; height: 50px;"></div>
				<div style="float: left; width: 28%;"><img  :src="config.logo" style="margin-left:15.5%; margin-top: 18px; cursor: pointer;"  @click="open('http://icdia.xmthz.cn',3,0)"/></div>
				<div style="height:100px; float: left;width: 57%; margin-left: 14%;">
					<div style=" height: 30px;">
						<div style="color:#979797;width: 66%; float: left;">
							<li class="top_menu" @click="open('http://xmtexpo.com',3,0)">首页</li>
							<li style="list-style: none; float:left; width: 32px; height: 30px;"></li>
							<li class="top_menu" @click="open('http://iccad.xmtexpo.com',3,0)">ICCAD-Expo</li>
							<li style="list-style: none; float:left; width: 32px; height: 30px;"></li>
							<li class="top_menu" @click="open('http://icdia.xmtexpo.com',3,0)">ICDIA-IC Show</li>
							<li style="list-style: none; float:left; width: 32px; height: 30px;"></li>
							<!-- <li class="top_menu">AEIF大会</li> -->
							<li class="top_menu" @click="open('http://aeif.xmtexpo.com',3,0)">AEIF大会</li>
						</div>
						<div style="width:25%;float:right;margin-top:-25px;margin-right: 5%; position: relative;">
							<el-input class="search" v-model="inputdata"   placeholder=""/>
							<!-- <img src="../assets/images/search.png" style="height: 25px; right:0px;top: 5px; position: absolute; z-index: 10001;"> -->
						</div>
					</div>
					<div style="clear: both;"></div>
					<div style="margin-top: 7px; ">
						<el-dropdown style="width: 13.5%">
						    <span class="el-button" style="letter-spacing:2px" size="large" @mouseover="show('/')" @mouseout="show(Active)"  :class="{ active: isActive === '/HeadComponent' || isActive === '/' }"   type="primary" @click="clik('','/HeadComponent')">ICDIA</span>
						</el-dropdown>
						<el-dropdown style="width: 14%">
						    <span class="el-button" :class="{ active: isActive === '/Meeting' }" @mouseover="show('/Meeting')" @mouseout="show(Active)" size="large"  type="primary" @click="clik('Notice','/Meeting')">会议概况</span>
							<template #dropdown>
							  <el-dropdown-menu @mouseover="show('/Meeting')" @mouseout="show(Active)">
							    <el-dropdown-item @click="clik('Notice','/Notice')">会议通知</el-dropdown-item>
							    <el-dropdown-item @click="clik('Highlights','/Highlights')">会议亮点</el-dropdown-item>
							    <el-dropdown-item @click="clik('Contemporaneous','/Contemporaneous')">同期论坛</el-dropdown-item>
							  </el-dropdown-menu>
							</template>
						</el-dropdown>
						<el-dropdown style="width: 14%">
						    <span :class="{ active: isActive === '/Exhibitions'}" class="el-button" size="large" @mouseover="show('/Exhibitions')" @mouseout="show(Active)"  type="primary" @click="clik('Registration','/Exhibitions')">我要参展</span>
							<template #dropdown>
							  <el-dropdown-menu @mouseover="show('/Exhibitions')" @mouseout="show(Active)">
							    <el-dropdown-item @click="clik('Registration','/Registration')">展商预登记</el-dropdown-item>
							    <el-dropdown-item @click="clik('Plan','/Plan')">展览平面图</el-dropdown-item>
							    <el-dropdown-item @click="clik('Handbook','/Handbook')">参展商手册</el-dropdown-item>
								<el-dropdown-item @click="clik('Guide','/Guide')">参展商须知</el-dropdown-item>
								<el-dropdown-item @click="clik('Information','/Information')">展商资料提交</el-dropdown-item>
								<el-dropdown-item @click="clik('Report','/Report')">展后报告</el-dropdown-item>
								<el-dropdown-item @click="clik('Hotel','/Hotel')">酒店及交通</el-dropdown-item>
								<!-- <el-dropdown-item @click="clik('Question','/Question')">展商常见问题</el-dropdown-item> -->
								<el-dropdown-item @click="clik('Link','/Link')">参展联系</el-dropdown-item>
							  </el-dropdown-menu>
							</template>
						</el-dropdown>
						<el-dropdown style="width: 14%">
						    <span :class="{ active: isActive === '/Attend' }" @mouseover="show('/Attend')" @mouseout="show(Active)" class="el-button" size="large"  type="primary" @click="clik('Apply','/Attend')">我要参会</span>
							<template #dropdown>
							  <el-dropdown-menu @mouseover="show('/Attend')" @mouseout="show(Active)">
							    <el-dropdown-item @click="clik('Apply','/Apply')">报名登记</el-dropdown-item>
							    <el-dropdown-item @click="clik('AttendGuide','/AttendGuide')">参会须知</el-dropdown-item>
							    <el-dropdown-item @click="clik('Businessman','/Businessman')">展商预览</el-dropdown-item>
								<!-- <el-dropdown-item @click="clik('AttendQuestion','/AttendQuestion')">观众常见问题</el-dropdown-item> -->
								<el-dropdown-item @click="clik('AttendLink','/AttendLink')">参会联系</el-dropdown-item>
							  </el-dropdown-menu>
							</template>
						</el-dropdown>
						<el-dropdown style="width: 14%;">
						    <span :class="{ active: isActive === '/Agenda' }" @mouseover="show('/Agenda')" @mouseout="show(Active)" class="el-button" size="large"  type="primary" @click="to('Agenda',1)">会议议程</span>
							<template #dropdown>
							  <el-dropdown-menu @mouseover="show('/Agenda')" @mouseout="show(Active)">
							    <el-dropdown-item v-for="(item, id) in agend" :key="id" @click="to('Agenda',item.id)" >{{ item.name }}</el-dropdown-item>
							  </el-dropdown-menu>
							</template>
						</el-dropdown>
						<el-dropdown style="width: 14%;">
						    <span :class="{ active: isActive === '/Review' }" @mouseover="show('/Review')" @mouseout="show(Active)" class="el-button" size="large"  type="primary" @click="to('Review',3)">往届回顾</span>
							<template #dropdown>
							  <el-dropdown-menu @mouseover="show('/Review')" @mouseout="show(Active)">
							    <el-dropdown-item v-for="(item, id) in review" :key="id" @click="to('Review',item.id)" >{{ item.name }}</el-dropdown-item>
							  </el-dropdown-menu>
							</template>
						</el-dropdown>
						<el-dropdown style="width: 14%;">
						    <span :class="{ active: isActive === '/News' }" @mouseover="show('/News')" @mouseout="show(Active)" class="el-button" size="large"  type="primary" @click="clik('News','/News')">活动新闻</span>
						</el-dropdown>
					</div>
				</div>
			</div>
	  </div>
	<router-view :searchVal="searchVal"></router-view>
	<Feet/>
</template>

<script>
	import localstorage from '@/utils/localStorage';
	import Feet from '@/components/Feet.vue'
	import request from '@/api/axios'
	import { UserFilled } from '@element-plus/icons-vue'

    export default {
        components: {
            Feet,
        },
      setup() {
        return {
        }
      },
      data() {
        return{
			searchVal: '',
			roleName: localstorage.getItem('roleName'),
			userName: '',
			banner: [],
			inputdata:'',
			config: {},
			agend: [],
			review: [],
			button:"el-button",
			buttonType:false,
			User:UserFilled,
			isActive:'/',
			Active:'/'
        }
      },
	  mounted() {
		if (localstorage.getItem('userName') == null) {
			this.userName = ''
		} else {
			this.userName = localstorage.getItem('userName');
		}
		if (this.$route.name !== "Search") {
			this.isActive = this.$route.matched[1].path;
		}
		window.addEventListener("scroll", this.onscroll);
		this.getData();
	  },
      methods:{
		  onscroll(){//s滚动的距离，offset默认为元素距离顶部的距离
				let s = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
				let dom = document.getElementById('tmenu')
				if(dom != null) {
					if (s>dom.offsetTop) {
						dom.style.boxShadow="0px 5px 5px 0px rgba(0,0,0,0.1)";
					} else {
						dom.style.boxShadow="";
					}
				}	
		  },
		  show(url){
		  			 this.isActive = url;
		  },
		  to (searchVal,id) {
			this.searchVal = searchVal
			if (searchVal === 'Agenda') {
				window.location.href = "/Agenda?id=" + id;
			} else {
				window.location.href = "/Review?id=" + id;
			}
		  },
		  getData() {
		  	request({url: '/icdia/hzIccadAgenda/list?status=1&OrderBy=sort desc', method: 'get'}).then(res => {
		  		if (res.code == 0) {
		  			this.agend = res.data.list
		  		}
		  	});
		  	request({url: '/icdia/hzIccadReview/list?status=1&OrderBy=sort desc', method: 'get'}).then(res => {
		  		if (res.code == 0) {
		  			this.review = res.data.list
		  		}
		  	});
		  	request({url: '/icdia/hzIccadConfig/list?status=1', method: 'get'}).then(res => {
		  		if (res.code == 0) {
		  			this.config = res.data.list[0]
					this.config.logo = 'http://115.29.189.37:8808/' + this.config.logo
		  		}
		  	});
		  },
          onimg(){
            this.$router.push("/")
          },
		  clik(searchVal,val){
			  this.$router.push('/' + searchVal);
			  this.searchVal = searchVal
		      this.isActive = val;
		  },
		  open(url,type,id) {
			  if (type === 1) {
				window.open(url)
			  } else if (type === 3) {
				  window.location.href= url
			  } else {
				this.isActive = url;
				this.$router.push( url + "?id=" + id);
			  }
		  },
		  search(){
			  this.isActive = "";
			  if(this.inputdata) {
				window.location.href = "/Search?keyword=" + this.inputdata;
			  }
		  },
		  logout(){
			  localstorage.removeItem('userName');
			  localstorage.removeItem('token');
			  localstorage.removeItem('roleName');
			  window.location.href = "/";
		  }
      }
    }



</script>

<style>
.search .el-input__inner{
	background:url(@/assets/images/search.png) no-repeat 120px center;  
	background-size: 25px;
}
.top_menu{
	list-style: none;
	float: left;
	font-size: 13px;
	font-style: normal;
	font-weight: normal; 
	cursor: pointer;
}
.top_menu:hover{
	border-bottom: 1px solid #979797;
}
.active {
    color: #3284c6 !important;
 }
.el-carousel__indicators--horizontal{
	display:none;
}
.el-popper.is-pure {
	box-shadow: none;
	border:none;
}
.el-dropdown__popper {
	position: relative;
	margin-left: 60px !important;
}
.el-dropdown-menu {
	width: 230px;
	padding-left: 22px;
}
.el-input__wrapper {
	position: relative;
}
.el-popper__arrow{
	display: none;
}
.el-dropdown .el-button:focus-visible {
	outline: none;
	color:#000;
	background-color: #fff;
}
.search .el-input__wrapper{
	border-radius: 0;
	border: 1px solid #252525 !important;
	box-shadow: none;
}

.search .el-input__wrapper.is-focus{
	box-shadow: none;
}
.input-border-style{
	border-color:#252525;
}
/* .el-dropdown .el-button:focus{
	color: #3284c6 !important;
	background-color: #ffffff !important;
} */
.el-dropdown .el-button{
	 font-size: 16px;
	 background-color: #ffffff; 
	 color: #252525;
	 border: none;
	 outline: none;
	 transition: background-color 0.3s ease; /* 添加过渡动画效果 */
	 text-align: center;
	 margin-top: 2px;
	 font-weight: 1000;
	 padding:0;
	 -webkit-font-smoothing:subpixel-antialiased;
}
.el-dropdown .el-button:hover{
	background-color: #ffffff;
	color: #000000;
	box-shadow: none;
}
.el-dropdown-menu {
	padding-bottom: 20px !important;
}
.el-popper{
	border-radius: 0 !important;
}

</style>
